<template>
	<div>
		<form v-on:submit.prevent="save">
			<div class="row mb-3">
				<div class="col-12 col-md-6 col-lg-8">
					<h1>FORTUS Carrier</h1>
				</div>
				<div class="col-12 col-md-6 col-lg-4 text-right">
					<button type="button" class="btn btn-light ml-1" v-on:click="close()"><i class="fa-regular fa-times" aria-hidden="true"></i> Close</button>
					<button type="submit" class="btn btn-primary ml-1"><i class="fa-regular fa-save" aria-hidden="true"></i> Save</button>
				</div>
			</div>

			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Carrier Information</b></div>

						<div class="card-body">
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="carrier" class="required">Carrier Name</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="carrier" v-model="carrier.carrier" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="carrier_code" class="required">FORTUS Carrier Code</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="carrier_code" v-model="carrier.carrier_code" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="sponsor_subscriber_id" class="required">Sponsor Subscriber ID (CGI)</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="sponsor_subscriber_id" v-model="carrier.sponsor_subscriber_id" class="form-control" />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="rating_service" class="required">Rating Service</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<select id="rating_service" v-model="carrier.rating_service" class="form-control custom-select" required>
										<option value="rws">Rating Web Service</option>
										<option value="rbws">RateBridge Web Service</option>
									</select>
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="contract_number">Contract Number</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="contract_number" v-model="carrier.contract_number" class="form-control" autocomplete="off" />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="fortus_username">Username</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="fortus_username" v-model="carrier.fortus_username" class="form-control" autocomplete="off" />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="fortus_password">Password</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="fortus_password" v-model="carrier.fortus_password" class="form-control" autocomplete="off" />
								</div>
							</div>
							<div class="form-row">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="auto">Automotive Quotes</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="auto" :value="true" v-model="carrier.auto">
										<label class="custom-control-label" for="auto"></label>
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="hab">Property Quotes</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="hab" :value="true" v-model="carrier.hab">
										<label class="custom-control-label" for="hab"></label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Convictions</b></div>

						<table class="table table-striped mb-0">
							<thead><tr><th>Type</th><th>Minimum</th><th>Maximum</th></tr></thead>
							<tbody>
								<tr>
									<td>Minor (fully licenced)</td>
									<td><input type="number" id="include_min_minor" v-model="carrier.include_min_minor" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_minor" v-model="carrier.exclude_max_minor" class="form-control" min="0" step="1" required /></td>
								</tr>
								<tr>
									<td>Minor (graduated licencing)</td>
									<td><input type="number" id="include_min_minor_gl" v-model="carrier.include_min_minor_gl" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_minor_gl" v-model="carrier.exclude_max_minor_gl" class="form-control" min="0" step="1" required /></td>
								</tr>
								<tr>
									<td>Major (fully licenced)</td>
									<td><input type="number" id="include_min_major" v-model="carrier.include_min_major" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_major" v-model="carrier.exclude_max_major" class="form-control" min="0" step="1" required /></td>
								</tr>
								<tr>
									<td>Major (graduated licencing)</td>
									<td><input type="number" id="include_min_major_gl" v-model="carrier.include_min_major_gl" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_major_gl" v-model="carrier.exclude_max_major_gl" class="form-control" min="0" step="1" required /></td>
								</tr>
								<tr>
									<td>Serious (fully licenced)</td>
									<td><input type="number" id="include_min_serious" v-model="carrier.include_min_serious" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_serious" v-model="carrier.exclude_max_serious" class="form-control" min="0" step="1" required /></td>
								</tr>
								<tr>
									<td>Serious (graduated licencing)</td>
									<td><input type="number" id="include_min_serious_gl" v-model="carrier.include_min_serious_gl" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_serious_gl" v-model="carrier.exclude_max_serious_gl" class="form-control" min="0" step="1" required /></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Lapses</b></div>

						<table class="table table-striped mb-0">
							<thead><tr><th>Type</th><th>Minimum</th><th>Maximum</th></tr></thead>
							<tbody>
								<tr>
									<td>Non-payment</td>
									<td><input type="number" id="include_min_nonpay" v-model="carrier.include_min_nonpay" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_nonpay" v-model="carrier.exclude_max_nonpay" class="form-control" min="0" step="1" required /></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Accidents</b></div>

						<table class="table table-striped mb-0">
							<thead><tr><th>Type</th><th>Minimum</th><th>Maximum</th></tr></thead>
							<tbody>
								<tr>
									<td>At-fault</td>
									<td><input type="number" id="include_min_atfault" v-model="carrier.include_min_atfault" class="form-control" min="0" step="1" required /></td>
									<td><input type="number" id="exclude_max_atfault" v-model="carrier.exclude_max_atfault" class="form-control" min="0" step="1" required /></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="row" v-if="canDelete">
				<div class="col-12 text-center">
					<button type="button" @click="remove" class="btn btn-danger btn-sm"><i class="fa-regular fa-trash-alt"></i> Delete Carrier</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'Carrier',
		data() {
			return {
				required_permissions: ["IDDQD", "ADMIN"],
				carrier: {
					carrier_id: null,
					carrier: "",
					carrier_code: "",
					rating_service: "rbws",
					contract_number: "",
					fortus_username: "",
					fortus_password: "",
					auto: 1,
					hab: 1,
					include_min_nonpay: 0,
					exclude_max_nonpay: 0,
					include_min_atfault: 0,
					exclude_max_atfault: 0,
					include_min_minor: 0,
					exclude_max_minor: 0,
					include_min_minor_gl: 0,
					exclude_max_minor_gl: 0,
					include_min_major: 0,
					exclude_max_major: 0,
					include_min_major_gl: 0,
					exclude_max_major_gl: 0,
					include_min_serious: 0,
					exclude_max_serious: 0,
					include_min_serious_gl: 0,
					exclude_max_serious_gl: 0
				}
			}
		},
		mounted()
		{
			this.load();
		},
		computed:
		{
			canDelete()
			{
				return this.carrier.carrier_id && this.userHasAction(this.user, ['IDDQD', 'ADMIN']);
			},
			...mapGetters([
				"user"
			])
		},
		methods:
		{
			load()
			{
				var carrier_id = this.$route.params.carrier_id;

				if(!carrier_id || carrier_id <= 0)
				{
					return;
				}

				this.$store.commit("startLoading");

				this.CORS('GET', "/carriers/" + carrier_id, null,
					(data) =>
					{
						this.carrier = data;
						this.carrier.auto = Boolean(this.carrier.auto);
						this.carrier.hab = Boolean(this.carrier.hab);
						this.$store.commit("stopLoading");
					},
					() =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error loading carrier.", true, null);
					});
			},
			save()
			{
				var url = "";
				var method = "";
				this.$store.commit("startLoading");

				if(Number(this.carrier.carrier_id) > 0)
				{
					url = "/carriers/" + this.carrier.carrier_id;
					method = "PUT";
				}
				else
				{
					url = "/carriers";
					method = "POST";
				}

				this.CORS(method, url, JSON.stringify(this.carrier),
					(data) =>
					{
						data.password = "";
						this.carrier = data;
						this.carrier.auto = Boolean(this.carrier.auto);
						this.carrier.hab = Boolean(this.carrier.hab);
						this.$store.commit("stopLoading");
						this.$router.push("/carriers").catch(() => { /* ignore error */ });
					},
					(response) =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error saving carrier: " + response.responseText, true, null);
					});
			},
			close()
			{
				this.$router.push("/carriers");
			},
			remove()
			{
				this.nerivon_confirm("Are you sure?", "<p>This carrier will be <b>DELETED</b>.</p>", "warning", true, (c) =>
				{
					if(c)
					{
						this.$store.commit("startLoading");

						this.CORS('DELETE', "/carriers/" + this.carrier.carrier_id, null,
							() =>
							{
								this.$store.commit("stopLoading");
								this.$router.push("/carriers").catch(() => { /* ignore error */ });
							},
							(response) =>
							{
								this.$store.commit("stopLoading");
								this.showError("Error", "Error deleting carrier: " + response.responseText, true, null);
							});
					}
				});
			}
		},
		watch: {
			'$route' (to, from)
			{
				if(to == from)
				{
					return;
				}

				this.load();
			}
		}
	}
</script>
